import axios from 'axios';
import Moment from 'vue-moment';
import { currentUserStore } from "@/store/modules";
import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/style.scss';
import './fontawesome';

// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Moment);

Vue.config.productionTip = false;

currentUserStore.loadUser().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
