import { Component, Prop, Vue } from 'vue-property-decorator';
import { currentUserStore } from "@/store/modules";

@Component
export default class Navigation extends Vue {

  public goToSettings() {
    this.$router.push({ name: "settings" });
    window?.scrollTo({ top: 0, behavior: "auto" });
  }

  public goToTasks() {
    if (currentUserStore.currentCenter
    && this.$route.name !== 'tasks') {
      this.$router.push({ name: 'tasks' });
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }

  public goToStats() {
    if (currentUserStore.currentCenter
    && this.$route.name !== 'stats') {
      this.$router.push({ name: 'stats' });
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }

  public goToSupervision() {
    if (currentUserStore.currentCenter
    && this.$route.name !== 'supervision') {
      this.$router.push({ name: 'supervision' });
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }
}
