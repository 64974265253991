import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Statistics from "@/views/Statistics.vue";
import Supervision from "@/views/Supervision.vue";
import Tasks from "@/views/Tasks.vue";
import Home from '../views/Home.vue';
import Settings from '../views/Settings.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
  },
  {
    path: '/stats',
    name: 'stats',
    component: Statistics,
  },
  {
    path: '/supervision',
    name: 'supervision',
    component: Supervision,
  },
  {
    path: '/test',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
