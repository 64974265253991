









































import moment, { Moment, Duration } from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TaskDetailMixin from "@/composition/mixins/TaskDetailMixin";
import { simpleDate, sinceInMinutes } from "@/composition/filters/date";
import { location } from "@/composition/filters/location";
import GenericAction from "@/components/Task/Action/Generic.vue";
import { Task } from "@/types/task.d";

@Component({
  name: "TaskDetailBodyGeneric",
  filters: {
    simpleDate,
    sinceInMinutes,
    location,
  },
  mixins: [
    TaskDetailMixin,
  ],
})
export default class Generic extends Vue {
  @Prop(Boolean)
  readonly active!: boolean;

  public alwaysShowOpenAt(task: Task): boolean {
    return task.type === 'custom_task'
      || task.type === 'order_departure_delay'
      || task.type === 'order_packing_delay'
      || task.type === 'missing_baked_product';
  }
}
