import { VuexModule, Module, Mutation, Action, RegisterOptions } from "vuex-class-modules";
import CurrentUserModule from "@/store/user/staff";
import { CloseTaskPayload, Task } from "@/types/task.d";
import type { TaskResponse } from "@/types/task.d";
import Tasks from '@/api/tasks';

@Module
export default class TaskModule extends VuexModule {

  public count = 0;

  public tasks: Task[] = [];

  private userModule: CurrentUserModule;

  private tasksApi: Tasks = Tasks.api;

  public constructor(userModule: CurrentUserModule, options: RegisterOptions) {
    super(options);
    this.userModule = userModule;
  }

  public get allTasks(): Task[] {
    return this.tasks;
  }

  public taskIndex(taskToFind: Task) {
    const index: number = this.tasks.findIndex(
      (task) => task.id === taskToFind.id,
    );

    if (index === -1) {
      throw new Error('No task found');
    }

    return index;
  }

  @Mutation
  public setTasks(tasks: Task[]) {
    this.tasks = tasks;
  }

  @Mutation
  public setCount(count: number) {
    this.count = count;
  }

  @Mutation
  public addTask(task: Task) {
    const inProgress: Task[] = this.tasks.filter((t) => t.status === 'in_progress');
    const otherTasks: Task[] = this.tasks.filter((t) => t.status !== 'in_progress');
    this.tasks = task.status === 'in_progress'
      ? [task, ...inProgress, ...otherTasks]
      : [...inProgress, task, ...otherTasks];
  }

  @Mutation
  public removeTask(taskId: number) {
    this.count -= 1;
    this.tasks = this.tasks.filter((task) => task.id !== taskId);
  }

  @Action
  public async loadTasks(): Promise<Task[]> {
    const user = this.userModule.loadedUser;
    const centerId = user.staff_info.current_center || user.staff_info.favourite_center;
    const tasksResponse = await this.tasksApi.getTasks(centerId || null, 'open');
    const tasks: Task[] = tasksResponse.data.results || [];
    this.setTasks(tasks);
    this.setCount(tasksResponse.data.count);
    return Promise.resolve<Task[]>(tasks);
  }

  @Action
  public async closeTask(taskId: number): Promise<Task> {
    const taskResponse: TaskResponse = await this.tasksApi.close(taskId);
    const task = taskResponse.data;
    this.removeTask(task.id);
    return Promise.resolve<Task>(task);
  }

  @Action
  public async closeTaskWithComment(payload: CloseTaskPayload): Promise<Task> {
    const taskResponse: TaskResponse = await this.tasksApi.close(payload.id, payload.comment);
    const task = taskResponse.data;
    this.removeTask(task.id);
    return Promise.resolve<Task>(task);
  }

  @Action
  public async startTask(taskId: number): Promise<Task> {
    const taskResponse: TaskResponse = await this.tasksApi.start(taskId);
    const task = taskResponse.data;
    this.removeTask(task.id);
    this.addTask(task);
    return Promise.resolve<Task>(task);
  }

  @Action
  public async rollbackTask(taskId: number): Promise<Task> {
    const taskResponse: TaskResponse = await this.tasksApi.rollback(taskId);
    const task = taskResponse.data;
    this.removeTask(taskId);
    this.addTask(task);
    return Promise.resolve<Task>(task);
  }
}
