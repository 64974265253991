



















































import moment, { Moment, Duration } from 'moment';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { Task } from "@/types/task.d";

@Component
export default class Generic extends Vue {
  @Prop(Boolean)
  readonly disabled!: boolean;

  @Prop(Object)
  readonly task!: Task;

  readonly comment: string = '';
}
