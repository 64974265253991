



























import TaskDetailMixin from "@/composition/mixins/TaskDetailMixin";
import { simpleDate, sinceInMinutes } from "@/composition/filters/date";
import { location } from "@/composition/filters/location";
import { title, priority } from "@/composition/filters/task";
import moment, { Moment, Duration } from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CloseTaskPayload, Task } from "@/types/task.d";
import GenericTask from "@/components/Task/Body/Generic.vue";
import GenericAction from "@/components/Task/Action/Generic.vue";

@Component({
  name: "TaskDetail",
  filters: {
    title,
    location,
    priority,
  },
  components: {
    GenericTask,
    GenericAction,
  },
})
export default class TaskDetail extends Vue {
  @Prop(Boolean)
  readonly disabled!: boolean;

  @Prop(Object)
  readonly task!: Task;

  private active = false;

  public close(payload: CloseTaskPayload): void {
    this.$emit('close:task', payload);
  }

  public rollback(id: number): void {
    this.$emit('rollback:task', id);
    this.active = false;
  }
}
