import { VuexModule, Module, Mutation, Action, RegisterOptions } from "vuex-class-modules";
import { StatLine } from "@/types/stats.d";
import StatsApi from '@/api/stats';
import CurrentUserModule from "@/store/user/staff";

@Module
export default class SummaryModule extends VuexModule {

  public stats: StatLine[] = [];

  private StatsApi: StatsApi = StatsApi.api;

  private userModule: CurrentUserModule

  public constructor(userModule: CurrentUserModule, options: RegisterOptions) {
    super(options);
    this.userModule = userModule;
  }

  public get all(): StatLine[] {
    return this.stats;
  }

  @Mutation
  public setStats(stats: StatLine[]) {
    this.stats = stats;
  }

  @Action
  public async loadStats(): Promise<StatLine[]> {
    if (!this.userModule.currentCenter) {
      return Promise.resolve<StatLine[]>([]);
    }
    const statLinePaginatedAxiosResponse = await this.StatsApi.getSummary(
      this.userModule.currentCenter,
    );
    const lines: StatLine[] = statLinePaginatedAxiosResponse.data;
    this.setStats(lines);
    return Promise.resolve<StatLine[]>(lines);
  }
}
