














import PackingFlux from "@/views/Stats/PackingFlux.vue";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    PackingFlux,
  },
})
export default class Supervision extends Vue {
}
