import { Component, Prop, Vue } from 'vue-property-decorator';
import { Colors } from "@/types/common.d";
import { DataTableRow, Measure, Series } from "@/types/stats.d";
import LineChartOptions = google.visualization.LineChartOptions;

@Component
export default class ChartHelper extends Vue {

  private baseColors: Colors = {
    main: "#212529",
    variant: "#4d4d4d",
    contrast: "white",
    lighterContrast: "#5d6474",
  }

  /**
   * @todo very cryptic
   * @see arrayToDataTable google
   * @param ticks
   * @param series
   */
  public zipSeries(ticks:Array<string|number|Date>, ...series:Series[]): DataTableRow[] {
    return ticks.map(
      (tick, index) => [
        tick,
        ...series.map((e) => e[index]?.value),
      ],
    );
  }

  public getLineChartOptions(colors: string[]): LineChartOptions {
    return {
      title: "Flux Prep",
      titlePosition: "top",
      // curveType: "function",
      backgroundColor: this.baseColors.main,
      hAxis: {
        textStyle: {
          color: this.baseColors.lighterContrast,
        },
        baselineColor: this.baseColors.main,
        gridlines: {
          count: 2,
          color: this.baseColors.contrast,
        },
      },
      vAxis: {
        baselineColor: this.baseColors.variant,
        gridlines: {
          count: 2,
          color: this.baseColors.variant,
        },
        textStyle: {
          color: this.baseColors.contrast,
        },
      },
      colors,
      chartArea: {
        width: '85%',
        backgroundColor: this.baseColors.main,
      },
      titleTextStyle: {
        color: this.baseColors.contrast,
      },
      legend: {
        position: 'bottom',
        alignment: 'start',
        textStyle: {
          fontSize: 12,
          color: this.baseColors.contrast,
        },
      },
    };
  }
}
