import { AxiosInstance } from 'axios';
import type { CenterPaginated, CenterPaginatedResponse } from "@/types/center.d";
import { UrlFactories } from "@/types/api.d";
import { deleevAPI } from './clients';

/**
 *
 */
export default class Center {
  private static singleton: Center;

  private urls: UrlFactories = {
    all: () => '/centers/',
  };

  /**
   * Client is automatically injected
   * @param client
   */
  public constructor(
    private client: AxiosInstance = deleevAPI,
  ) {}

  /**
   * return a promise of the current user
   */
  public async all(): Promise<CenterPaginatedResponse> {
    return this.client.get<CenterPaginated>(this.urls.all());
  }

  /**
   *
   */
  public static get api(): Center {
    if (!this.singleton) {
      this.singleton = new this();
    }
    return this.singleton;
  }
}
