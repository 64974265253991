













import { Component, Vue } from 'vue-property-decorator';
import { centerStore, currentUserStore } from "@/store/modules";
import CenterModule from "@/store/center/center";
import CurrentUserModule from "@/store/user/staff";
import { Center } from "@/types/center.d";

interface SelectOption {
  value: number;
  text: string;
  notEnabled?:boolean;
}

@Component
export default class Settings extends Vue {
  public selectedCenter = currentUserStore.currentCenter || 0;

  private centers: CenterModule = centerStore;

  private user: CurrentUserModule = currentUserStore;

  public get allCenters(): SelectOption[] {
    const title: SelectOption[] = [{
      value: 0,
      text: "Choisissez un centre",
      notEnabled: true,
    }];

    return title.concat(this.centers.all.map(
      (center: Center) => ({ value: center.id, text: center.name }),
    ));
  }

  public newCenter() {
    this.user.changeCenter(this.selectedCenter);
    this.$router.push('/tasks');
  }

  public mounted() {
    if (this.centers.all.length === 0) {
      this.centers.loadCenters();
    }
  }
}
