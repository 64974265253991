




























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AppBar extends Vue {
  @Prop(String)
  readonly where!: string;

  public goHome(): void {
    this.$emit('nav:home');
  }
}
