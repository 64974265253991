import { VuexModule, Module, Mutation, Action, RegisterOptions } from "vuex-class-modules";
import { Center } from "@/types/center.d";
import CenterApi from '@/api/center';

@Module
export default class CenterModule extends VuexModule {

  public centers: Center[] = [];

  private centerApi: CenterApi = CenterApi.api;

  public get all(): Center[] {
    return this.centers;
  }

  public get byId(): (id: number) => Center|undefined {
    return (id: number) => this.centers.filter((c) => c.id === id).pop();
  }

  @Mutation
  public setCenters(centers: Center[]) {
    this.centers = centers;
  }

  @Action
  public async loadCenters(): Promise<Center[]> {
    const centerPaginatedAxiosResponse = await this.centerApi.all();
    const centers: Center[] = centerPaginatedAxiosResponse.data.results;
    this.setCenters(centers);
    return Promise.resolve<Center[]>(centers);
  }
}
