






























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  @Prop(Number)
  readonly countTasks!: number;

  @Prop(String)
  readonly active!: string;

  public get hasTasks(): boolean {
    return this.countTasks > 0;
  }
}
