import { Component, Prop, Vue } from 'vue-property-decorator';
import { Task } from "@/types/task.d";

@Component
export default class TaskDetailMixin extends Vue {

  @Prop(Object)
  readonly task!: Task;

  /**
   * Tells if the packer is going somewhere
   */
  public get has_next_location(): boolean {
    return !!(this.task && this.task.packer_location
      && this.task.packer_location.last_known_location
      !== this.task.packer_location.next_likely_location
      && this.task.packer_location.next_likely_location !== '');
  }

  /**
   * Tells if the packer has a known location
   */
  public get has_known_location(): boolean {
    return !!(this.task && this.task.packer_location
      && this.task.packer_location.is_last_seen_relevant
      && this.task.packer_location.last_known_location !== '');
  }

  public get last_known_location(): string {
    return this.task.packer_location?.last_known_location || '';
  }

  public get next_likely_location(): string {
    return this.task.packer_location?.next_likely_location || '';
  }

  public get last_seen(): string {
    return this.task.packer_location?.when || '';
  }
}
