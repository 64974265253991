






















import { Component, Vue, Mixins } from 'vue-property-decorator';
import EmptyTasks from '@/components/Task/EmptyTask.vue';
import TaskDetail from '@/components/Task/TaskDetail.vue';
import { taskStore, currentAppStore } from "@/store/modules";
import TaskModule from "@/store/tasks/management";
import { CloseTaskPayload, Task } from "@/types/task.d";
import AppModule from "@/store/app/app";
import AppStateAware from "@/composition/mixins/AppStateAware";

@Component({
  components: {
    EmptyTasks,
    TaskDetail,
  },
})
export default class Tasks extends Mixins(AppStateAware) {

  private tasks: TaskModule = taskStore;

  private app: AppModule = currentAppStore;

  public loaded = false;

  public get allTasks(): Task[] {
    return this.tasks.allTasks;
  }

  public close(payload: CloseTaskPayload): void {
    this.exec<Task>(async () => this.tasks.closeTaskWithComment(payload));
  }

  public start(taskId: number): void {
    this.exec<Task>(async () => {
      const task = await this.tasks.startTask(taskId);
      window?.scrollTo(0, 0);
      return task;
    });
  }

  public rollback(taskId: number): void {
    this.exec<Task>(async () => this.tasks.rollbackTask(taskId));
  }

  private async mounted(): Promise<null> {
    if (this.allTasks.length > 0) {
      return Promise.resolve(null);
    }

    await this.app.withLoading({
      module: 'task',
      callback: () => this.tasks.loadTasks(),
    });

    this.loaded = true;
    return Promise.resolve(null);
  }
}
