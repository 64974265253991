import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";

interface LoadingPayload<T> {
  module: string;
  callback: () => Promise<T>;
}

@Module
export default class AppModule extends VuexModule {

  public loadings: string[] = [];

  public get working(): boolean {
    return this.loadings.length > 0;
  }

  @Mutation
  public addLoading(module: string) {
    this.loadings = [
      module,
      ...this.loadings.filter((m) => m !== module),
    ];
  }

  @Mutation
  public removeLoading(module: string) {
    this.loadings = this.loadings.filter((m) => m !== module);
  }

  public async withLoading<T>({ module, callback } : LoadingPayload<T>): Promise<T> {
    this.addLoading(module);
    return callback().then(() => {
      this.removeLoading(module);
    }) as Promise<T>;
  }

}
