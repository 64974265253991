












import { Component, Vue, Prop } from "vue-property-decorator";
import { GChart, google } from "vue-google-charts";
import SupervisionModule from "@/store/stats/supervision";
import { supervisionStore } from "@/store/modules";
import ChartHelper from "@/composition/mixins/ChartHelper";
import { Measure, DataTableRowWithLabel } from "@/types/stats.d";

type LineChartOptions = google.visualization.LineChartOptions;

@Component({
  components: {
    GChart,
  },
})
export default class PackingFlux extends Vue {

  private ready = false;

  private chart = new ChartHelper();

  private stats: SupervisionModule = supervisionStore;

  /**
   * get data charts
   */
  public get chartDatas(): DataTableRowWithLabel {
    return [
      ["heures", "IPH"],
      ...this.chart.zipSeries(
        this.stats.packingStat.map((m) => m.timeframe.end_time),
        this.stats.packingStat,
      ),
    ];
  }

  public get ticks(): string[] {
    return this.stats.all.packing.map((e) => e.timeframe.end_time);
  }

  public get options(): LineChartOptions {
    return this.chart.getLineChartOptions([
      "#9D132A",
    ]);
  }

  public async mounted(): Promise<null> {
    this.ready = this.stats.packingStatReady;
    await this.stats.load();
    this.ready = true;
    return Promise.resolve(null);
  }
}
