import { AxiosInstance } from 'axios';
import { Task, TaskPaginated } from "@/types/task.d";
import type { TaskResponse, TaskPaginatedResponse } from "@/types/task.d";
import { UrlFactories } from "@/types/api.d";
import { deleevAPI } from './clients';

/**
 *
 */
export default class Tasks {
  private static singleton: Tasks;

  private urls: UrlFactories = {
    tasks: (status: string) => `/team-leader/tasks/?status=${status}`,
    close: (taskId: number) => `/team-leader/tasks/${taskId}/close/`,
    start: (taskId: number) => `/team-leader/tasks/${taskId}/start/`,
    rollback: (taskId: number) => `/team-leader/tasks/${taskId}/rollback/`,
    tasksByCenter: (centerId: number, status: string) => `/team-leader/tasks/?center=${centerId}&status=${status}`,
  };

  /**
   * Client is automatically injected
   * @param client
   */
  public constructor(
    private client: AxiosInstance = deleevAPI,
  ) {}

  /**
   * Get all tasks
   * @param centerId
   * @param status
   */
  public getTasks(centerId: number|null, status = 'open'): Promise<TaskPaginatedResponse> {
    const path: string = centerId
      ? this.urls.tasksByCenter(centerId, status)
      : this.urls.tasks(status);
    return this.client.get<TaskPaginated>(path);
  }

  /**
   * close the given task
   * @param taskId
   * @param comment
   */
  public close(taskId: number, comment = ''): Promise<TaskResponse> {
    return this.client.patch<Task>(this.urls.close(taskId), {
      comment,
    });
  }

  /**
   * close the given task
   * @param taskId
   */
  public start(taskId: number): Promise<TaskResponse> {
    return this.client.patch<Task>(this.urls.start(taskId), {});
  }

  /**
   * close the given task
   * @param taskId
   */
  public rollback(taskId: number): Promise<TaskResponse> {
    return this.client.patch<Task>(this.urls.rollback(taskId), {});
  }

  /**
   *
   */
  public static get api(): Tasks {
    if (!this.singleton) {
      this.singleton = new this();
    }
    return this.singleton;
  }
}
