import store from "@/store/index";
import CurrentUserModule from "@/store/user/staff";
import TaskModule from "@/store/tasks/management";
import AppModule from "@/store/app/app";
import CenterModule from "@/store/center/center";
import SummaryModule from "@/store/stats/summary";
import SupervisionModule from "@/store/stats/supervision";

export const currentAppStore = new AppModule({ store, name: "currentApp" });
export const centerStore = new CenterModule({ store, name: "store" });
export const currentUserStore = new CurrentUserModule({ store, name: "currentUser" });
export const taskStore = new TaskModule(currentUserStore, { store, name: 'tasks' });
export const summaryStore = new SummaryModule(currentUserStore, { store, name: 'summary' });
export const supervisionStore = new SupervisionModule(
  currentUserStore,
  { store, name: 'supervision' },
);
