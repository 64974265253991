




















import { Component, Vue } from "vue-property-decorator";
import AppBar from '@/components/AppBar.vue';
import { currentAppStore, currentUserStore, centerStore, taskStore } from "@/store/modules";
import { Center } from "@/types/center.d";
import { StaffInfo, UnknownUser } from "@/types/user.d";
import CurrentUserModule from "@/store/user/staff";
import Footer from "@/components/Footer.vue";
import Navigation from "@/composition/mixins/Navigation";
import TaskModule from "@/store/tasks/management";
import Summary from "@/components/Stats/Summary.vue";

@Component({
  components: {
    Summary,
    Footer,
    AppBar,
  },
  mixins: [
    Navigation,
  ],
})
export default class App extends Vue {

  private timer = 0;

  private tasks: TaskModule = taskStore;

  public readonly userStore: CurrentUserModule = currentUserStore;

  public get working() {
    return currentAppStore.working;
  }

  public get summaryEnabled(): boolean {
    return true;
  }

  public get center(): string {
    return centerStore.byId(
      currentUserStore.currentCenter || 0,
    )?.name || '';
  }

  public refresh(): void {
    if (this.userStore.currentCenter) {
      this.tasks.loadTasks();
    }
  }

  public startAutoRefresh(): void {
    this.timer = setInterval(() => {
      this.refresh();
    }, 15000);
  }

  public mounted() {
    this.startAutoRefresh();
    const user = currentUserStore.user;
    const center: null|number = currentUserStore.currentCenter;

    if (!center && this.$route.name !== 'home') {
      this.$router.push({ name: 'home' });
    }
  }

  public beforeDestroy(): void {
    clearInterval(this.timer);
  }
}
