










































import { unit } from "@/composition/filters/stats";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { summaryStore } from "@/store/modules";
import SummaryModule from "@/store/stats/summary";
import { StatLine } from "@/types/stats.d";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

@Component({
  filters: {
    unit,
  },
  components: {
    FontAwesomeIcon,
  },
})
export default class Summary extends Vue {

  private timer = 0;

  private stats: SummaryModule = summaryStore;

  public is_last_iph(stat: StatLine): boolean {
    return stat.label === '20 minutes';
  }

  public is_long_iph(stat: StatLine): boolean {
    return stat.label === '60 minutes';
  }

  public is_frozen(stat: StatLine): boolean {
    return stat.label === 'surgelés';
  }

  public is_item(stat: StatLine): boolean {
    return stat.label === 'à préparer';
  }

  public startAutoRefresh(): void {
    this.timer = setInterval(() => {
      this.stats.loadStats();
    }, 60000 * 2); // every 2 minutes
  }

  private async mounted(): Promise<null> {
    await this.stats.loadStats();
    this.startAutoRefresh();
    return Promise.resolve<null>(null);
  }

  public beforeDestroy(): void {
    clearInterval(this.timer);
  }
}
