import moment, { Duration, Moment } from "moment";

export function simpleDate(date: string): string {
  const today: string = moment().format('DD/MM/YYYY');
  const dayOfDate: string = moment(date).format('DD/MM/YYYY');

  return today === dayOfDate
    ? moment(date).format('HH:mm:ss')
    : moment(date).format('HH:mm:ss (DD/MM/YYYY)');
}

export function sinceInMinutes(dateAsString: string): number {
  const startTime: Moment = moment(dateAsString);
  const now: Moment = moment();
  const duration: Duration = moment.duration(now.diff(startTime));
  const minutes: number = duration.asMinutes();
  return Math.ceil(minutes);
}
