import { Component, Prop, Vue } from 'vue-property-decorator';
import { currentAppStore } from "@/store/modules";

@Component
export default class AppStateAware extends Vue {

  /**
   * Execute a callback, with app knowing it's loading something
   * @param callback
   */
  public async exec<T>(callback: () => Promise<T>): Promise<T> {
    const result = await currentAppStore.withLoading({
      module: 'tasks',
      callback,
    });
    return result;
  }

  /**
   * Tells if app is currently processing something
   */
  public get working(): boolean {
    return currentAppStore.working;
  }
}
