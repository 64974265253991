import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { StaffUser, UnknownUser } from "@/types/user.d";
import User from "@/api/user";

@Module
export default class CurrentUserModule extends VuexModule {

  private userApi: User = User.api;

  public user: StaffUser|UnknownUser = {
    first_name: 'John',
    last_name: 'Doe',
    anonymous: true,
  }

  /**
   * Tells if the current user is not anonymous
   */
  public get anonymous(): boolean {
    return this.user.anonymous;
  }

  /**
   * Tells if the current user is not anonymous
   */
  public get currentCenter(): number|null {
    const user = this.user as StaffUser;
    return user?.staff_info?.current_center || null;
  }

  /**
   * Get the current user or throw exception
   */
  public get loadedUser(): StaffUser {
    if (this.user.anonymous) {
      throw Error('User is not loaded');
    }
    return this.user as StaffUser;
  }

  /**
   * Give the full name of the current user
   */
  get fullName(): string {
    const user: StaffUser = this.user as StaffUser;
    const name = `${user.first_name} ${user.last_name}`;
    return name.replace(/^\s/, '')
      ? name
      : user.email;
  }

  // mutations
  @Mutation
  public setUser(user: StaffUser) {
    this.user = user;
  }

  @Mutation
  public setCurrentCenter(centerId: number|null) {
    const user = this.user as StaffUser;
    user.staff_info.current_center = centerId;
    this.user = user;
  }

  // actions
  @Action
  public changeCenter(centerId: number): Promise<null> {
    localStorage.setItem('centerid', `${centerId}`);
    this.setCurrentCenter(centerId);
    return Promise.resolve(null);
  }

  @Action
  public unsetCenter(): Promise<null> {
    localStorage.removeItem('centerid');
    this.setCurrentCenter(null);
    return Promise.resolve(null);
  }

  @Action
  public async loadUser(): Promise<null> {
    try {
      const user = await this.userApi.autoLogin();
      this.setUser(user);
      this.loadCenterIdFromLocalStorage(user);
      return Promise.resolve<null>(null);
    } catch (error) {
      if (window && window.location) {
        const adminBaseUrl: string = process.env.VUE_APP_DELEEV_ADMIN_URL;
        const adminLoginUrl = `${adminBaseUrl}/account/login`;
        const currentUrl = window.location.href;
        window.location.href = `${adminLoginUrl}?next=${currentUrl}`;
      }
      return Promise.reject(Error('You should be redirected'));
    }
  }

  @Action
  public loadCenterIdFromLocalStorage(user: StaffUser): void {
    const centerId: string|null = localStorage.getItem('centerid');
    if (centerId !== null && !user.staff_info.current_center) {
      this.changeCenter(parseInt(centerId, 10));
    }
  }
}
