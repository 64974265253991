import { VuexModule, Module, Mutation, Action, RegisterOptions } from "vuex-class-modules";
import CurrentUserModule from "@/store/user/staff";
import { Measure, SupervisionStat } from "@/types/stats.d";
import Stats from "@/api/stats";

@Module
export default class SupervisionModule extends VuexModule {

  private api: Stats = Stats.api;

  private userStore: CurrentUserModule

  private supervision: SupervisionStat = {
    packing: [],
    products: [],
  }

  public constructor(userStore: CurrentUserModule, options: RegisterOptions) {
    super(options);
    this.userStore = userStore;
  }

  public get packingStat(): Measure[] {
    return this.supervision.packing;
  }

  public get packingStatReady(): boolean {
    return this.supervision.packing.length > 0;
  }

  public get all(): SupervisionStat {
    return this.supervision;
  }

  @Mutation
  public setSupervision(stats: SupervisionStat): void {
    this.supervision = stats;
  }

  @Action
  public async load(): Promise<SupervisionStat> {
    if (!this.userStore.currentCenter) {
      return Promise.resolve(this.supervision);
    }
    const response = await this.api.getSupervision(this.userStore.currentCenter);
    this.setSupervision(response.data);
    return this.supervision;
  }
}
