import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  Token,
  Credentials,
  User as Account,
  TokenResponse,
  UserResponse, StaffUser,
} from '@/types/user.d';
import { UrlFactories } from "@/types/api.d";
import { deleevAPI } from './clients';

/**
 *
 */
export default class User {
  private static singleton: User;

  private urls: UrlFactories = {
    auth: () => '/auth/login/',
    current: () => '/me/',
    token: () => '/admin-user/',
  };

  /**
   * Client is automatically injected
   * @param client
   */
  public constructor(
    private client: AxiosInstance = deleevAPI,
  ) {}

  /**
   * Authenticate a user through deleev API
   * @param credentials
   * @return Promise<Token>
   */
  public async authenticate(credentials: Credentials): Promise<TokenResponse> {
    const conf: AxiosRequestConfig = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };
    const params = new URLSearchParams();
    params.append('email', credentials.email);
    params.append('password', credentials.password);
    return this.client.post<Token>(this.urls.auth(), params, conf);
  }

  /**
   * Login the user automatically
   * @param document
   */
  public async autoLogin(document: Document = window?.document): Promise<StaffUser> {
    const regexp = /api_token=(\w+);?/;
    const cookies: string = document?.cookie || '';
    const token: string|undefined = (cookies.match(regexp) || [])[1];
    console.log('>>>>>>>>>>> cookies', cookies);

    if (token === undefined) {
      console.log('>>>>>>>>>>> no token', cookies);
      return Promise.reject(Error('No matching token in cookies'));
    }

    const promise = this.verifyToken({
      auth_token: token || '',
    });

    promise.then(() => {
      const headers = this.client.defaults.headers;
      headers.common['Authorization'] = `Token ${token}`;
    });

    return promise;
  }

  /**
   * Verify if current token is valid
   * @param token
   */
  public async verifyToken(token: Token): Promise<StaffUser> {
    const conf: AxiosRequestConfig = {
      headers: { Authorization: `Token ${token.auth_token}` },
    };
    const response = await this.client.get<Partial<StaffUser>>(this.urls.token(), conf);
    return new Promise<StaffUser>((resolve, reject) => {
      if (!response || response.status > 400) {
        console.log('>>>>>>>>>>> invalid token', token);
        reject(Error('invalid token'));
      } else {
        response.data.anonymous = false;
        resolve(response.data as StaffUser);
      }
    });
  }

  /**
   * return a promise of the current user
   */
  public async current(): Promise<UserResponse> {
    return this.client.get<Account>(this.urls.current());
  }

  /**
   *
   */
  public static get api(): User {
    if (!this.singleton) {
      this.singleton = new this();
    }
    return this.singleton;
  }
}
