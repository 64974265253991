import { AxiosInstance } from 'axios';
import { StatLine, StatLinesResponse, SupervisionResponse, SupervisionStat } from "@/types/stats.d";
import { UrlFactories } from "@/types/api.d";
import { deleevAPI } from './clients';

/**
 *
 */
export default class Stats {
  private static singleton: Stats;

  private urls: UrlFactories = {
    summary: (centerid: number) => `/team-leader/summary/?center=${centerid}`,
    supervision: (centerid: number) => `/team-leader/supervision/?center=${centerid}`,
  };

  /**
   * Client is automatically injected
   * @param client
   */
  public constructor(
    private client: AxiosInstance = deleevAPI,
  ) {}

  /**
   * Get all tasks
   * @param centerId
   */
  public getSummary(centerId: number): Promise<StatLinesResponse> {
    const path: string = this.urls.summary(centerId);
    return this.client.get<StatLine[]>(path);
  }

  public getSupervision(centerId: number): Promise<SupervisionResponse> {
    const path: string = this.urls.supervision(centerId);
    return this.client.get<SupervisionStat>(path);
  }

  /**
   *
   */
  public static get api(): Stats {
    if (!this.singleton) {
      this.singleton = new this();
    }
    return this.singleton;
  }
}
