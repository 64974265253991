import { Task } from "@/types/task.d";

// eslint-disable-next-line
enum TaskPriority {
  LOW = "priority-low",
  NORMAL = "priority-normal",
  IMPORTANT = "priority-important",
  HIGH = "priority-high",
  URGENT = "priority-urgent"
}

const PriorityMap: {[key: string]: string} = {
  "100": TaskPriority.LOW,
  "200": TaskPriority.NORMAL,
  "300": TaskPriority.IMPORTANT,
  "400": TaskPriority.HIGH,
  "500": TaskPriority.URGENT,
};

export function title(task: Task): string {
  if (task.type === 'packer_iph') {
    return `IPH ${task.measure_value}`;
  }

  if (task.type === 'order_prep_sleeping_time') {
    const match: RegExpMatchArray|null = task.comment.match(/#\d+/);
    const id: string = (match ? match[0] || '' : '');
    return `Commande dormante (${Math.floor(task.measure_value / 60)} min)`;
  }

  return task.label;
}

export function priority(task: Task): string {

  for (const level of Object.keys(PriorityMap)) {
    const priorityLevel: number = parseInt(level, 10);

    if (task.priority <= priorityLevel) {
      return PriorityMap[level];
    }
  }
  return TaskPriority.URGENT;
}
